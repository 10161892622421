'use client'
import { Container } from '@/ds/atoms/Container'
import { Typography } from '@/ds/atoms/Typography'
import { Button, ButtonVariant } from '@/ds/atoms/Button'
import Image from 'next/image'
import AutoScrollCarousel from '@/ds/atoms/AutoScrollCarousel/AutoScrollCarousel'
import { cn } from '@/ds/utils/cn'
import { getStrapiMedia } from '@/utils/media'
import { StrapiImageComponent } from '@/types/strapiComponents'
import PasswordCheckIcon from '@/assets/icons/password-check'

export type CtaPropsType = {
  btnText: string
  link: string
  target?: '_self' | '_blank' | '_parent' | '_top'
  btnVariant: ButtonVariant
}

type contentPropertiesType = {
  [key: string]: {
    titleColor: string
    descriptionColor: string
    backgroundColor: string
    textColor: string
    scrollTitleColor: string
    ctaColor: 'white' | 'primary'
  }
}

type ModuleHeroSectionPropsType = {
  moduleTitle?: string
  title: string
  description: string
  ctaList: CtaPropsType[]
  heroImage: StrapiImageComponent
  logosTitle?: string
  logos?: StrapiImageComponent[]
  className?: string
  variant?: 'primary' | 'white'
}

const ModuleHeroSection = ({
  moduleTitle,
  title,
  description,
  heroImage,
  ctaList,
  logosTitle,
  logos,
  className,
  variant = 'white',
}: ModuleHeroSectionPropsType): React.JSX.Element => {
  const contentProperties: contentPropertiesType = {
    white: {
      titleColor: 'text-primary',
      descriptionColor: 'text-grays-lighter',
      textColor: 'text-primary',
      backgroundColor: 'bg-white',
      scrollTitleColor: 'text-grays-light',
      ctaColor: 'primary',
    },
    primary: {
      titleColor: 'text-white',
      descriptionColor: 'text-white',
      textColor: 'text-white',
      backgroundColor: 'bg-primary rounded-br-[64px] md:rounded-br-[160px]',
      scrollTitleColor: 'text-white',
      ctaColor: 'white',
    },
  }
  let failSafeVariant = variant ?? 'white'

  return (
    <div
      className={cn(
        contentProperties[failSafeVariant].backgroundColor,
        className
      )}
    >
      <Container className="pt-14 xl:pt-24 !pb-16 rounded-br-[160px]">
        <div className="flex flex-col lg:flex-row items-center justify-center lg:gap-12">
          <div className="flex flex-col items-start justify-center gap-2 lg:gap-6 w-full lg:w-[55%]">
            <div className="flex gap-2 flex-col">
              {moduleTitle && (
                <div className="gap-1 flex items-center">
                  <PasswordCheckIcon className="w-6 h-6 text-primary" />
                  <Typography
                    variant="header4"
                    text={moduleTitle}
                    className="text-pale-blue-light font-semibold"
                    as="h3"
                  />
                </div>
              )}
              <Typography
                variant="header1"
                text={title}
                className={contentProperties[failSafeVariant].titleColor}
                as="h2"
              />
            </div>
            <Typography
              variant="body20"
              text={description}
              className={contentProperties[failSafeVariant].descriptionColor}
            />
            <div className="flex flex-wrap flex-row mt-0 sm:mt-4 mb-8 sm:mb-0 gap-2 sm:gap-6">
              {ctaList?.map((cta, index) => (
                <Button
                  key={index}
                  variant={cta?.btnVariant}
                  label={cta?.btnText}
                  withIcon
                  as="a"
                  color={contentProperties[failSafeVariant].ctaColor}
                  href={cta?.link}
                  target={cta?.target}
                  rel="noopener noreferrer"
                  className="w-fit min-w-[160px] justify-between"
                />
              ))}
            </div>
          </div>
          <div className="w-full lg:w-[45%] m-auto">
            {heroImage && (
              <div className="m-auto relative w-[295px] h-[295px] md:w-[500px] md:h-[400px] mt-8 lg:mt-0 lg:w-[40vw] lg:min-h-[50vh] xl:w-full xl:h-[464px] flex-shrink-0">
                <Image
                  src={getStrapiMedia(heroImage?.url)}
                  alt={heroImage?.alternativeText}
                  fill
                  className="object-contain"
                  priority
                  loading="eager"
                />
              </div>
            )}
          </div>
        </div>
        {!!logos?.length && logosTitle && (
          <>
            <Typography
              variant="body20Bold"
              text={logosTitle}
              className={cn(
                'text-center mt-8 xl:mt-12',
                contentProperties[failSafeVariant].scrollTitleColor
              )}
            />
            <AutoScrollCarousel
              content={logos?.map((logo, index) => (
                <div
                  key={index}
                  className="relative w-[72px] sm:w-24 h-12 sm:h-16 flex-shrink-0 mt-5  sm:mt-6"
                >
                  <Image
                    src={getStrapiMedia(logo?.url)}
                    alt={logo?.alternativeText}
                    fill
                    className="object-contain"
                    priority
                    loading="eager"
                  />
                </div>
              ))}
              itemClassName={contentProperties[failSafeVariant].textColor}
            />
          </>
        )}
      </Container>
    </div>
  )
}

export default ModuleHeroSection
