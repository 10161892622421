import { cn } from '@/ds/utils/cn'

const patterns = {
  youtube: {
    isEnabled: true,
    classNames: 'min-h-[320px] md:min-h-[420px] lg:min-h-[500px]',
    regex: /\[Youtube:\[(.*?)\]:End\]/,
    getEmbedUrl: (url: string) => {
      return url
    },
  },
  facebook: {
    isEnabled: false,
    classNames: '',
    regex: /\[Facebook:\[(.*?)\]:End\]/,
    getEmbedUrl: (url: string) => {
      return url
    },
  },
  twitter: {
    isEnabled: false,
    classNames: 'm-auto',
    regex: /\[Twitter:\[(.*?)\]:End\]/,
    getEmbedUrl: (url: string) => {
      // Handle both old twitter.com and new x.com URLs
      const tweetId = url.match(
        /(?:twitter\.com|x\.com)\/\w+\/status\/(\d+)/
      )?.[1]
      return tweetId
        ? `https://platform.twitter.com/embed/Tweet.html?id=${tweetId}`
        : null
    },
  },
  linkedin: {
    isEnabled: false,
    classNames: '',
    regex: /\[LinkedIn:\[(.*?)\]:End\]/,
    getEmbedUrl: (url: string) => {
      return url
    },
  },
}

const RenderIframeContent = ({
  text,
  type,
}: {
  text: string
  type: string
}) => {
  if (!text || !type) return null

  // Check each platform's pattern
  return Object.entries(patterns).map(
    ([platform, { regex, getEmbedUrl, classNames, isEnabled }], index) => {
      const match = text.match(regex)
      if (match) {
        const originalUrl = match[1]
        const embedUrl = getEmbedUrl(originalUrl)
        if (!embedUrl || !isEnabled) return null
        return (
          <span
            key={index}
            className={cn(
              'min-h-[170px] relative block w-full my-3',
              classNames
            )}
            // style={{
            //   ...(iframeHeight ? { height: `${iframeHeight}px` } : {}),
            // }}
          >
            <iframe
              // ref={iframeRef}
              className="absolute top-0 start-0 w-full h-full"
              src={embedUrl}
              title={`${platform} embed`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              // onLoad={onLoad}
            />
          </span>
        )
      }
    }
  )
}

export default RenderIframeContent
