'use client'
import React, { useRef, useEffect, useState } from 'react'
import { cn } from '@/ds/utils/cn'
import { useLocale } from 'next-intl'
import styles from './AutoScrollCarousel.module.css'

type AutoScrollCarouselProps = {
  content: React.ReactNode[]
  direction?: 'forward' | 'reverse'
  speed?: number
  containerClassName?: string
  itemClassName?: string
}

const AutoScrollCarousel: React.FC<AutoScrollCarouselProps> = ({
  content,
  direction = 'forward',
  speed = 20,
  containerClassName,
  itemClassName,
}) => {
  const locale = useLocale()
  const isRTL = locale === 'ar'
  const containerRef = useRef<HTMLDivElement>(null)
  const [repeatCount, setRepeatCount] = useState(3)

  // For RTL, we need to flip the direction because the scroll direction is reversed
  const effectiveDirection = isRTL
    ? direction === 'forward'
      ? 'reverse'
      : 'forward'
    : direction

  useEffect(() => {
    const calculateRepeatCount = () => {
      if (!containerRef.current) return

      const containerWidth = containerRef.current.offsetWidth
      const trackElement = containerRef.current.querySelector(
        `.${styles.carouselTrack}`
      )
      const singleSetWidth = trackElement?.children[0]?.scrollWidth || 0

      if (singleSetWidth === 0) return

      // Calculate how many times we need to repeat to fill container width
      const needed = Math.ceil((containerWidth * 2) / singleSetWidth)
      setRepeatCount(Math.max(needed, 3)) // Minimum 3 sets for smooth scrolling
    }

    // Initial calculation
    calculateRepeatCount()

    // Add resize listener
    window.addEventListener('resize', calculateRepeatCount)

    // Force recalculation after a short delay to ensure proper rendering
    const timeout = setTimeout(calculateRepeatCount, 100)

    return () => {
      window.removeEventListener('resize', calculateRepeatCount)
      clearTimeout(timeout)
    }
  }, [content])

  return (
    <div
      ref={containerRef}
      className={cn(styles.carouselWrapper, containerClassName)}
      dir={isRTL ? 'rtl' : 'ltr'}
    >
      <div
        className={cn(
          styles.carouselTrack,
          styles[effectiveDirection],
          isRTL ? styles.rtl : ''
        )}
        style={{
          animationDuration: `${speed}s`,
        }}
      >
        {Array.from({ length: repeatCount }).map((_, duplicationIndex) => (
          <div
            key={`duplicate-${duplicationIndex}`}
            className={cn(
              styles.carouselItem,
              'gap-6 flex items-center justify-start',
              itemClassName
            )}
          >
            {React.Children?.map(content, (item, index) => (
              <div
                key={`${duplicationIndex}_${index}`}
                className="flex-shrink-0"
              >
                {item}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default AutoScrollCarousel
