'use client'
import { useState } from 'react'
import { Container } from '@/ds/atoms/Container'
import { Typography } from '@/ds/atoms/Typography'
import { Button, ButtonVariant } from '@/ds/atoms/Button'
import Image from 'next/image'
import PasswordCheckIcon from '@/assets/icons/password-check'
import { cn } from '@/ds/utils/cn'
import { getStrapiMedia } from '@/utils/media'
import { ScrollTabs } from '@/ds/atoms/ScrollTabs'
import { Accordion } from '@/ds/atoms/Accordion'
import styles from './Modules.module.css'
import { StrapiImageComponent } from '@/types/strapiComponents'
import { motion } from 'framer-motion'

type ModulesSectionCardPropsType = {
  id: string
  title: string
  description: string
  cta: {
    btnText: string
    link: string
    target?: '_self' | '_blank' | '_parent' | '_top'
    btnVariant: ButtonVariant
  }
  heroImage: StrapiImageComponent
  showIcon?: boolean
  icon?: StrapiImageComponent
}

type contentPropertiesType = {
  titleColor: string
  descriptionColor: string
  backgroundColor: string
  textColor: string
  scrollTitleColor: string
}

export type ModulesSectionPropsType = {
  title: string
  description: string
  cards: ModulesSectionCardPropsType[]
  transitionInterval: number
  cta: {
    btnText: string
    link: string
    target?: '_self' | '_blank' | '_parent' | '_top'
  }
  className?: string
}

const ModulesSection = ({
  title,
  description,
  cards,
  transitionInterval,
  cta,
  className,
}: ModulesSectionPropsType): React.JSX.Element => {
  const [currentStep, setCurrentStep] = useState(0)
  const [openAccordionIndex, setOpenAccordionIndex] = useState(0)

  const handleAccordionClick = (index: number) => {
    setOpenAccordionIndex(index === openAccordionIndex ? null : index)
  }
  const contentProperties: contentPropertiesType = {
    titleColor: 'text-primary-darkest',
    descriptionColor: 'text-grays-light',
    textColor: 'text-primary',
    backgroundColor: 'bg-white',
    scrollTitleColor: 'text-grays-light',
  }

  const interval = transitionInterval * 1000

  return (
    <div className={cn(contentProperties.backgroundColor, className)}>
      <Container className="pt-14 lg:pt-24 pb-16">
        <div className="flex flex-col items-center w-full justify-center ">
          <div className="flex flex-col items-start justify-center gap-2 w-full">
            <Typography
              variant="header1"
              text={title}
              className={contentProperties.titleColor}
              as="h2"
            />
            <Typography
              variant="body20"
              text={description}
              className={cn(
                contentProperties.descriptionColor,
                'max-w-[720px]'
              )}
            />
          </div>
          <div className="h-8 lg:h-16" />
          <div className="lg:flex justify-between w-full flex-col lg:flex-row hidden">
            <ScrollTabs
              tabs={cards?.map((module, index) => ({
                id: module.title,
                tabNode:
                  currentStep === index ? (
                    <motion.div
                      className={cn(
                        'mt-5 flex py-5 ps-2 pe-7 w-[450px] gap-[6px] items-start bg-gradient-blue-lightest cursor-pointer',
                        !module.showIcon && 'ps-5'
                      )}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 20 }}
                      transition={{ duration: 0.4 }}
                    >
                      {module.showIcon && (
                        <div className="h-8 flex items-center">
                          {module.icon ? (
                            <div className="relative w-6 h-6 flex items-center">
                              <Image
                                src={getStrapiMedia(module.icon?.url)}
                                alt={module.icon?.alternativeText}
                                fill
                                className="text-primary-lighter object-cover"
                                priority
                                loading="eager"
                              />
                            </div>
                          ) : (
                            <PasswordCheckIcon className="w-6 h-6 text-primary-lighter" />
                          )}
                        </div>
                      )}
                      <div className="flex flex-col items-start">
                        <Typography
                          text={module.title}
                          variant="header4"
                          className="text-grays"
                          as="h3"
                        />
                        <Typography
                          text={module.description}
                          variant="body20"
                          className="text-grays-lighter mt-2"
                        />
                        <Button
                          variant={module.cta?.btnVariant ?? 'onlyText'}
                          label={module.cta?.btnText}
                          as="a"
                          href={module.cta?.link}
                          target={module.cta?.target}
                          rel="noopener noreferrer"
                          className="mt-4"
                          withIcon
                        />
                      </div>
                    </motion.div>
                  ) : (
                    <motion.div
                      className="my-5 flex items-center w-[450px] gap-[6px] cursor-pointer"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.4 }}
                    >
                      {module.showIcon && (
                        <div className="h-8 flex items-center">
                          {module.icon ? (
                            <div className="relative w-6 h-6 flex items-center">
                              <Image
                                src={getStrapiMedia(module.icon?.url)}
                                alt={module.icon?.alternativeText}
                                fill
                                priority
                                loading="eager"
                                className="text-grays-light object-cover"
                              />
                            </div>
                          ) : (
                            <PasswordCheckIcon className="w-6 h-6 text-grays-lighter" />
                          )}
                        </div>
                      )}
                      <Typography
                        text={module.title}
                        variant="header4"
                        className="text-grays-lighter"
                        as="h3"
                      />
                    </motion.div>
                  ),
              }))}
              activeIndex={currentStep}
              setActiveIndex={setCurrentStep}
              interval={interval}
              tabProps={{
                alignment: 'vertical',
                showLoader: true,
                containerClassname: 'gap-4',
                color: 'primary',
              }}
            />

            <motion.div
              className="relative w-[295px] h-[295px] lg:w-[450px] lg:[h-375px] xl:w-[900px] xl:h-[600px] flex-shrink-0"
              key={currentStep}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.4 }}
            >
              <Image
                src={getStrapiMedia(cards[currentStep].heroImage?.url)}
                alt={cards[currentStep].heroImage?.alternativeText}
                fill
                className="object-contain"
                priority
                loading="eager"
              />
            </motion.div>
          </div>
          <div className="lg:hidden flex flex-col items-center gap-5 w-full">
            {cards?.map((module, index) => (
              <Accordion
                key={module.id}
                open={openAccordionIndex === index}
                setCurrentStep={() => handleAccordionClick(index)}
                title={
                  <div className="flex items-center gap-1">
                    {module.showIcon && (
                      <>
                        {module.icon ? (
                          <div className="relative w-6 h-6 flex items-center">
                            <Image
                              src={getStrapiMedia(module.icon?.url)}
                              alt={module.icon?.alternativeText}
                              fill
                              priority
                              loading="eager"
                              className="w-6 h-6 group-open:text-primary-darker text-grays-lighter object-cover"
                            />
                          </div>
                        ) : (
                          <PasswordCheckIcon className="w-6 h-6 group-open:text-primary-darker text-grays-lighter " />
                        )}
                      </>
                    )}
                    <Typography
                      text={module.title}
                      variant="header4"
                      className="group-open:text-grays text-grays-lighter"
                      as="h3"
                    />
                  </div>
                }
                content={
                  <div className="flex flex-col items-center w-full">
                    <div className="flex flex-col items-start w-full">
                      <Typography
                        text={module.description}
                        variant="body14"
                        className={contentProperties.descriptionColor}
                      />
                      <Button
                        variant={module.cta?.btnVariant ?? 'onlyText'}
                        label={module.cta?.btnText}
                        as="a"
                        href={module.cta?.link}
                        target={module.cta?.target}
                        rel="noopener noreferrer"
                        className="w-fit mt-2"
                        withIcon
                      />
                    </div>
                    <div className="relative w-[295px] h-[197px] mt-5 flex-shrink-0">
                      <Image
                        src={getStrapiMedia(cards[currentStep].heroImage?.url)}
                        alt={module.heroImage?.alternativeText}
                        fill
                        priority
                        loading="eager"
                        className="object-contain"
                      />
                    </div>
                  </div>
                }
                containerClassName={cn(styles.details, 'p-2')}
                contentClassName="p-0 m-0 border-none bg-transparent mt-2"
              />
            ))}
          </div>
          <div className="h-8 lg:h-12" />
          {cta && (
            <Button
              variant="filled"
              label={cta?.btnText}
              as="a"
              href={cta?.link}
              target={cta?.target || '_blank'}
              rel="noopener noreferrer"
              withIcon
            />
          )}
        </div>
      </Container>
    </div>
  )
}

export default ModulesSection
