import { Button } from '@/ds/atoms/Button'
import { cn } from '@/ds/utils/cn'
import React from 'react'

type PaginationProps = {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
  showPreviousButton?: boolean
  nextButtonLabel?: string
  previousButtonLabel?: string
}

// Pagination Component
const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  showPreviousButton = false,
  nextButtonLabel = 'Next',
  previousButtonLabel = 'Previous',
}: PaginationProps) => {
  const getPageNumbers = () => {
    const delta = 2
    const range = []
    const rangeWithDots = []

    for (
      let i = Math.max(2, currentPage - delta);
      i <= Math.min(totalPages - 1, currentPage + delta);
      i++
    ) {
      range.push(i)
    }

    if (currentPage - delta > 2) {
      rangeWithDots.push(1, '...')
    } else {
      rangeWithDots.push(1)
    }

    rangeWithDots.push(...range)

    if (currentPage + delta < totalPages - 1) {
      rangeWithDots.push('...', totalPages)
    } else if (totalPages > 1) {
      rangeWithDots.push(totalPages)
    }

    return rangeWithDots
  }

  return (
    <div className="flex justify-center items-center gap-4 mt-6">
      {showPreviousButton && (
        <Button
          as="button"
          variant="outlined"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 text-grays-lighter py-3 rounded-[75px] border-0 bg-grays-bg min-h-[48px]"
          label={previousButtonLabel}
        />
      )}
      <div className="flex gap-4">
        {getPageNumbers().map(pageNum =>
          pageNum === '...' ? (
            <span
              key={`dots-${pageNum}`}
              className="hidden px-3 py-2"
            >
              ...
            </span>
          ) : (
            <Button
              as="button"
              key={pageNum}
              variant={currentPage === pageNum ? 'filled' : 'outlined'}
              onClick={() => onPageChange(pageNum)}
              className={cn('h-12 w-12 rounded-full', {
                'px-4 text-grays-lighter py-3 border-0 bg-grays-bg min-h-[48px]':
                  currentPage !== pageNum,
              })}
              label={pageNum}
            />
          )
        )}
      </div>

      <Button
        as="button"
        variant="outlined"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages && totalPages > 0}
        color="white"
        className={cn(
          'px-4 text-grays-lighter py-3 rounded-[75px] border-0 bg-grays-bg min-h-[48px]',
          {
            'cursor-not-allowed': currentPage === totalPages,
          }
        )}
        label={nextButtonLabel}
      />
    </div>
  )
}

export default Pagination
