'use client'
import { useState, useMemo, useEffect } from 'react'
import { Container } from '@/ds/atoms/Container'
import { Typography } from '@/ds/atoms/Typography'
import { Button, ButtonColor, ButtonVariant } from '@/ds/atoms/Button'
import { ScrollTabs } from '@/ds/atoms/ScrollTabs'
import Image from 'next/image'
import AutoScrollCarousel from '@/ds/atoms/AutoScrollCarousel/AutoScrollCarousel'
import { cn } from '@/ds/utils/cn'
import { getStrapiMedia } from '@/utils/media'
import { StrapiImageComponent } from '@/types/strapiComponents'
import { motion } from 'framer-motion'

export type HeroSectionCardPropsType = {
  details: {
    title: string
    description: string
    cta: {
      btnText: string
      link: string
      target?: '_self' | '_blank' | '_parent' | '_top'
      btnVariant: ButtonVariant
    }
  }
  bgColor: 'primary' | 'white'
  heroImage: StrapiImageComponent
  tabName: string
  logosTitle: string
  logos: StrapiImageComponent[]
}

type contentPropertiesType = {
  titleColor: string
  descriptionColor: string
  buttonColor: ButtonColor
  backgroundColor: string
  textColor: string
  scrollTitleColor: string
}

export type HeroSectionPropsType = {
  cards: HeroSectionCardPropsType[]
  transitionInterval: number
  className?: string
  isFirstSection?: boolean
}

const HeroSection = ({
  cards,
  transitionInterval,
  className,
  isFirstSection,
}: HeroSectionPropsType): React.JSX.Element => {
  const [currentStep, setCurrentStep] = useState(0)

  const {
    details: { title, description, cta },
    bgColor,
    heroImage,
    logosTitle,
    logos,
  } = cards[currentStep]

  const tabNames = useMemo(
    () => cards?.map(card => ({ id: card.tabName })),
    [cards]
  )

  const interval = transitionInterval * 1000

  const contentProperties: contentPropertiesType =
    bgColor === 'primary'
      ? {
          titleColor: 'text-white',
          descriptionColor: 'text-white',
          buttonColor: 'white',
          backgroundColor: 'bg-primary',
          textColor: 'text-white',
          scrollTitleColor: 'text-white',
        }
      : {
          titleColor: 'text-primary-darkest',
          descriptionColor: 'text-grays-lighter',
          textColor: 'text-primary',
          buttonColor: 'primary',
          backgroundColor: 'bg-gradient-light-primary-white',
          scrollTitleColor: 'text-grays-light',
        }

  useEffect(() => {
    if (isFirstSection) {
      const navbarColor = bgColor === 'primary' ? 'dark' : 'light'
      window.dispatchEvent(
        new CustomEvent('navbarColorChange', { detail: navbarColor })
      )
    }
  }, [bgColor, isFirstSection])

  return (
    <div
      className={cn(
        contentProperties.backgroundColor,
        'rounded-br-[64px] md:rounded-br-[160px]',
        className
      )}
    >
      <motion.div
        key={currentStep}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.8 }}
        className="flex flex-col items-start justify-center gap-2 lg:gap-6 w-full"
      >
        <Container className={cn('pt-14 lg:pt-24 pb-16')}>
          <div className="flex flex-col lg:flex-row items-center justify-center lg:gap-24">
            <div className="flex flex-col items-start justify-center gap-2 lg:gap-6 w-full">
              <Typography
                variant="header1"
                text={title}
                className={contentProperties.titleColor}
              />
              <Typography
                variant="body20"
                text={description}
                className={contentProperties.descriptionColor}
              />
              {cta && (
                <Button
                  variant={cta?.btnVariant ?? 'filled'}
                  color={contentProperties.buttonColor}
                  label={cta?.btnText}
                  withIcon
                  as="a"
                  href={cta?.link}
                  target={cta?.target}
                  rel="noopener noreferrer"
                  className="mt-0 sm:mt-4"
                />
              )}
              <div className="h-4" />
              {heroImage && (
                <div className="relative w-[295px] h-[295px] lg:hidden mb-4 flex-shrink-0">
                  <Image
                    src={getStrapiMedia(heroImage?.url)}
                    alt={heroImage?.alternativeText}
                    fill
                    className="object-contain"
                    priority
                    loading="eager"
                  />
                </div>
              )}
              <ScrollTabs
                tabs={tabNames}
                activeIndex={currentStep}
                setActiveIndex={setCurrentStep}
                tabProps={{
                  color: contentProperties.buttonColor,
                  alignment: 'horizontal',
                  // gap: 4,
                  showLoader: true,
                }}
                interval={interval}
              />
            </div>
            <div className="w-full lg:w-1/2 m-auto">
              {heroImage && (
                <div className="relative w-[464px] h-[464px] hidden lg:block flex-shrink-0 m-auto">
                  <Image
                    src={getStrapiMedia(heroImage?.url)}
                    alt={heroImage?.alternativeText}
                    fill
                    className="object-contain"
                    priority
                    loading="eager"
                  />
                </div>
              )}
            </div>
          </div>
          <Typography
            variant="body20Bold"
            text={logosTitle}
            className={cn(
              'text-center mt-8 lg:mt-12',
              contentProperties.scrollTitleColor
            )}
          />
          <AutoScrollCarousel
            content={logos?.map((logo, index) => (
              <div
                key={index}
                className="relative w-[72px] h-12 sm:h-16 flex-shrink-0 mt-5  lg:mt-6"
              >
                <Image
                  src={getStrapiMedia(logo?.url)}
                  alt={logo?.alternativeText}
                  fill
                  className="object-contain"
                  priority
                  loading="eager"
                />
              </div>
            ))}
            itemClassName={contentProperties.textColor}
          />
        </Container>
      </motion.div>
    </div>
  )
}

export default HeroSection
