/* eslint-disable react/prop-types */
const ChevronLeftIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    {...props}
  >
    <g id="chevron-left">
      <path
        id="Icon"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0893 4.41058C12.7639 4.08514 12.2362 4.08514 11.9108 4.41058L6.91078 9.41058C6.58535 9.73602 6.58535 10.2637 6.91078 10.5891L11.9108 15.5891C12.2362 15.9145 12.7639 15.9145 13.0893 15.5891C13.4147 15.2637 13.4147 14.736 13.0893 14.4106L8.67857 9.99984L13.0893 5.58909C13.4147 5.26366 13.4147 4.73602 13.0893 4.41058Z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default ChevronLeftIcon
