'use client'
import { useState } from 'react'
import { Container } from '@/ds/atoms/Container'
import { Typography } from '@/ds/atoms/Typography'
import { Button, ButtonVariant } from '@/ds/atoms/Button'
import Image from 'next/image'
import PasswordCheckIcon from '@/assets/icons/password-check'
import { cn } from '@/ds/utils/cn'
import { getStrapiMedia } from '@/utils/media'
import { ScrollTabs } from '@/ds/atoms/ScrollTabs'
import { Accordion } from '@/ds/atoms/Accordion'
import styles from './FeatureSection.module.css'
import { StrapiImageComponent } from '@/types/strapiComponents'

type FeaturesSectionCardPropsType = {
  id: string
  title: string
  summary: string
  description: string
  cta?: {
    btnVariant: ButtonVariant
    btnText: string
    link: string
    target?: '_self' | '_blank' | '_parent' | '_top'
  }
  heroImage: StrapiImageComponent
  showIcon?: boolean
  icon?: StrapiImageComponent
}

type contentPropertiesType = {
  titleColor: string
  descriptionColor: string
  backgroundColor: string
  textColor: string
  scrollTitleColor: string
}

export type FeaturesSectionPropsType = {
  cards: FeaturesSectionCardPropsType[]
  className?: string
}

const ModulesSection = ({
  cards,
  className,
}: FeaturesSectionPropsType): React.JSX.Element => {
  const [currentStep, setCurrentStep] = useState(0)

  const contentProperties: contentPropertiesType = {
    titleColor: 'text-primary-darkest',
    descriptionColor: 'text-grays-lighter',
    textColor: 'text-primary',
    backgroundColor: 'bg-white',
    scrollTitleColor: 'text-grays-light',
  }

  return (
    <div className={cn(contentProperties.backgroundColor, className)}>
      <Container className="px-5 py-16 sm:p-[100px]">
        <div className="flex flex-col items-center w-full justify-center xl:border xl:border-primary-lighter rounded-lg xl:p-5">
          <div className="xl:flex justify-between w-full flex-col hidden">
            <ScrollTabs
              tabs={cards?.map((module, index) => ({
                id: module.title,
                tabNode: (
                  <div
                    className={cn(
                      'flex flex-col p-4 min-w-[180px] gap-[6px]',
                      currentStep === index ? 'bg-gradient-blue-light' : '',
                      'hover:bg-pale-blue-transparent cursor-pointer'
                    )}
                  >
                    <div className="flex items-center gap-[6px]">
                      {module.showIcon && (
                        <div className="h-8 flex items-center">
                          {module.icon ? (
                            <div className="relative w-6 h-6 flex items-center">
                              <Image
                                src={getStrapiMedia(module.icon?.url)}
                                alt={module.icon?.alternativeText}
                                fill
                                className={cn(
                                  currentStep === index
                                    ? ' text-primary-darker'
                                    : 'text-grays-lighter',
                                  'object-cover'
                                )}
                                priority
                                loading="eager"
                              />
                            </div>
                          ) : (
                            <PasswordCheckIcon
                              className={cn(
                                'w-6 h-6 ',
                                currentStep === index
                                  ? ' text-primary-darker'
                                  : 'text-grays-lighter'
                              )}
                            />
                          )}
                        </div>
                      )}

                      <Typography
                        as="h2"
                        text={module.title}
                        variant="body16Bold"
                        className={
                          currentStep === index
                            ? ' text-primary-darker'
                            : 'text-grays-lighter'
                        }
                      />
                    </div>
                    <Typography
                      text={module.summary}
                      variant="body16"
                      className={
                        currentStep === index
                          ? 'text-grays-bold'
                          : 'text-grays-lighter'
                      }
                    />
                  </div>
                ),
              }))}
              activeIndex={currentStep}
              setActiveIndex={setCurrentStep}
              tabProps={{
                alignment: 'horizontal',
                showLoader: false,
                containerClassname: 'gap-4',
                color: 'primary',
                showNavButtons: false,
              }}
              mode="manual"
            />
            <div className="h-12" />
            <div className="flex w-full items-center content-center justify-between">
              <div className="flex flex-col w-[530px] gap-8">
                <Typography
                  text={cards[currentStep].description}
                  variant="body20"
                  className={contentProperties.descriptionColor}
                />
                {cards[currentStep].cta && (
                  <Button
                    variant={cards[currentStep].cta?.btnVariant ?? 'filled'}
                    label={cards[currentStep].cta?.btnText}
                    withIcon
                    as="a"
                    href={cards[currentStep].cta?.link}
                    target={cards[currentStep].cta?.target}
                    className="w-fit"
                  />
                )}
              </div>
              <div className="relative w-[630px] h-[420px] flex-shrink-0">
                <Image
                  src={getStrapiMedia(cards[currentStep].heroImage?.url)}
                  alt={cards[currentStep].heroImage?.alternativeText}
                  fill
                  className="object-contain"
                  priority
                  loading="eager"
                />
              </div>
            </div>
          </div>
          <div className="xl:hidden flex flex-col items-center gap-5 sm:gap-8 w-full">
            {cards?.map(module => (
              <Accordion
                key={module.id}
                title={
                  <div className="flex items-center gap-[6px]">
                    {module.showIcon && (
                      <>
                        {module.icon ? (
                          <div className="relative w-6 h-6 flex items-center">
                            <Image
                              src={getStrapiMedia(module.icon?.url)}
                              alt={module.icon?.alternativeText}
                              fill
                              className="w-6 h-6 group-open:text-primary-darker text-grays-lighter object-cover"
                              priority
                              loading="eager"
                            />
                          </div>
                        ) : (
                          <PasswordCheckIcon className="w-6 h-6 group-open:text-primary-darker text-grays-lighter " />
                        )}
                      </>
                    )}

                    <Typography
                      text={module.title}
                      variant="body16Bold"
                      className="group-open:text-grays text-grays-lighter"
                    />
                  </div>
                }
                content={
                  <div className="flex flex-col items-center">
                    <Typography
                      text={module.description}
                      variant="body20"
                      className={contentProperties.descriptionColor}
                    />
                    <div className="relative w-[319px] h-[197px] my-5 flex-shrink-0">
                      <Image
                        src={getStrapiMedia(cards[currentStep].heroImage?.url)}
                        alt={module.heroImage?.alternativeText}
                        fill
                        className="object-contain"
                        priority
                        loading="eager"
                      />
                    </div>
                    {module.cta && (
                      <Button
                        variant="filled"
                        label={module.cta?.btnText}
                        withIcon
                        as="a"
                        href={module.cta?.link}
                        target={module.cta?.target}
                        className="w-fit"
                      />
                    )}
                  </div>
                }
                containerClassName={cn(
                  styles.details,
                  'py-[12px] px-2 sm:py-6 sm:px-4'
                )}
                contentClassName="p-0 m-0 border-none bg-transparent mt-2"
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ModulesSection
