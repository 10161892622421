import CalenderCheck01 from '@/assets/icons/CalenderCheck01'
import { Button } from '@/ds/atoms/Button'
import { useTranslations } from 'next-intl'

const ThankBox = () => {
  const t = useTranslations()
  return (
    <div className="px-4 md:px-8 py-4 md:py-14">
      <div className="flex flex-col gap-[56px] justify-center">
        <div className="flex flex-col justify-center items-center gap-2">
          <CalenderCheck01 className="w-8 h-8" />
          <p className=" text-2xl font-semibold text-[#027A48] text-center">
            {t('demo_form.thanks_message')}
          </p>
          <p className=" text-sm font-normal text-center text-[#344054]">
            {t('demo_form.our_working_hours')}
          </p>
        </div>
        <div className="flex flex-col gap-3 items-center">
          <p className="text-lg font-semibold text-[#344054] text-center">
            {t('demo_form.have_some_time')}
          </p>
          <Button
            variant="outlined"
            color="primary"
            as="a"
            href="https://blog.jisr.net/ar"
            label={t('demo_form.explore_blog')}
          />
        </div>
      </div>
    </div>
  )
}

export default ThankBox
