'use client'
import dynamic from 'next/dynamic'
import { VideoPlayerProps } from './types'
import { useEffect, useRef } from 'react'

const VideoWrapper = dynamic(
  () => import('./VideoWrapper').then(mod => mod.VideoWrapper),
  {
    ssr: false,
  }
)

export const VideoPlayer = ({
  src,
  poster,
  title,
  autoplay = false,
  muted = false,
  controls = [
    'play',
    'progress',
    'current-time',
    'mute',
    'volume',
    'fullscreen',
  ], // Default controls
  options,
  onReady,
  className = 'relative w-full aspect-video', // default class
}: VideoPlayerProps) => {
  const videoId = useRef(
    `video-player-${Math.random().toString(36).substr(2, 9)}`
  )
  const playerInstanceRef = useRef<any>(null)

  useEffect(() => {
    return () => {
      if (playerInstanceRef.current) {
        playerInstanceRef.current.destroy()
        playerInstanceRef.current = null
      }
    }
  }, [])

  // const handleReady = (player: any) => {
  //   playerInstanceRef.current = player

  //   // Call original onReady callback if provided
  //   if (onReady) {
  //     onReady(player)
  //   }
  // }

  const playerOptions = {
    ...options,
    controls,
    hideControls: false, // Prevent controls from auto-hiding
    clickToPlay: true,
    displayDuration: true,
  }

  return (
    <div className={className}>
      <VideoWrapper
        options={playerOptions}
        onReady={onReady}
        videoId={videoId.current}
      >
        <video
          id={videoId.current}
          playsInline
          autoPlay={autoplay}
          muted={muted}
          poster={poster}
          className="w-full h-full object-cover"
        >
          <source
            src={src}
            type="video/mp4"
          />
          {title && (
            <track
              kind="captions"
              label={title}
            />
          )}
        </video>
      </VideoWrapper>
    </div>
  )
}
