import styles from './ScrollTabs.module.css' // Importing styles
import { Button, ButtonColor } from '../Button'
import { useLocale } from 'next-intl'
import ChevronLeft from '@/assets/icons/ChevronLeft'
import ChevronRight from '@/assets/icons/ChevronRight'
import { cn } from '@/ds/utils/cn'

export type Tab = {
  id: string // Unique identifier for each tab
  content?: React.ReactNode // Content to display in the section
  tabNode?: React.ReactNode // Custom tab node
}

export type TabsProperties = {
  showLoader: boolean // Show loader while fetching data
  alignment: 'vertical' | 'horizontal' // Alignment of tabs
  containerClassname?: string // Custom class for the container
  color: ButtonColor
  visualType?: 'tabsWithText' | 'slider'
  showNavButtons?: boolean
}

export type ScrollTabsProps = {
  tabs: Tab[] // Array of tabs to cycle through
  interval?: number // Interval for auto-scroll in milliseconds (default: 5000)
  onTabChange?: (currentTabId: string) => void // Callback when tab changes
  activeIndex: number // Index of the active tab
  setActiveIndex: React.Dispatch<React.SetStateAction<number>> // Setter for active index
  tabProps?: TabsProperties // Properties for tabs
  mode?: 'manual' | 'auto' // Manual mode for switching tabs
}

const buttons = [
  { id: 'left', icon: <ChevronLeft className="w-6 h-6" /> },
  { id: 'right', icon: <ChevronRight className="w-6 h-6" /> },
]

const defaultTabProps: TabsProperties = {
  showLoader: true,
  visualType: 'tabsWithText',
  showNavButtons: false,
  alignment: 'horizontal',
  containerClassname: '',
  color: 'primary',
}

const ScrollTabs = ({
  tabs,
  interval = 5000,
  onTabChange,
  activeIndex = 0,
  setActiveIndex,
  tabProps,
  mode = 'auto',
}: ScrollTabsProps) => {
  const locale = useLocale()
  const isManualMode = mode === 'manual'
  const handleTabClick = (index: number) => {
    setActiveIndex(index) // Switch tab on click
    onTabChange?.(tabs[index].id) // Trigger callback, if provided
  }

  const handleAnimationEnd = (isReverse: boolean = false) => {
    let nextIndex = activeIndex + (isReverse ? -1 : 1)
    if (nextIndex < 0) {
      nextIndex = tabs?.length - 1 // Wrap around to the last tab
    } else {
      nextIndex = nextIndex % tabs?.length // Calculate the next index
    }
    handleTabClick(nextIndex)
  }

  const mergedTabProps = { ...defaultTabProps, ...tabProps }

  return (
    <div className="flex gap-4 flex-col">
      {/* Tab buttons */}
      <div className="flex justify-between">
        <div
          className={cn(
            'flex items-center',
            mergedTabProps.alignment === 'vertical' ? 'flex-col' : 'flex-row',
            mergedTabProps.containerClassname
          )}
        >
          {tabs?.map((tab, index) => (
            <div
              key={tab.id}
              className="flex flex-col w-fit"
              onClick={() => handleTabClick(index)}
            >
              {tab?.tabNode && tab.tabNode}
              {!tab?.tabNode &&
                mergedTabProps.visualType === 'tabsWithText' && (
                  <Button
                    label={tab.id}
                    className="px-0 justify-start me-[16px]"
                    variant="onlyText"
                    color={mergedTabProps.color}
                  />
                )}
              {!tab?.tabNode && mergedTabProps.visualType === 'slider' && (
                <button
                  key={index}
                  className={`w-10 sm:w-16 h-1 rounded-full transition-colors ${
                    activeIndex === index ? 'bg-primary' : 'bg-grays-lightest'
                  }`}
                />
              )}
              {/* Progress bar under the active tab */}
              {activeIndex === index && !isManualMode && (
                <div
                  key={tab.id + activeIndex} // Unique key to restart animation
                  className={`${styles.progressBar} ${
                    mergedTabProps.showLoader ? '' : 'hidden'
                  } ${mergedTabProps.color === 'primary' ? 'bg-primary' : 'bg-white'}`}
                  style={{
                    animationDuration: `${interval}ms`,
                  }}
                  onAnimationEnd={() => handleAnimationEnd()}
                ></div>
              )}
            </div>
          ))}
        </div>
        {isManualMode && mergedTabProps.showNavButtons && (
          <div className="flex gap-4 p-4">
            {buttons?.map(button => (
              <button
                key={button.id}
                className={cn(
                  'w-12 h-12 rounded-full flex items-center justify-center',
                  'transition-colors duration-200',
                  'bg-white border-2 border-primary text-primary',
                  'hover:bg-primary-lightest',
                  'active:bg-primary active:text-white active:border-primary',
                  locale === 'ar' ? 'transform rotate-180' : ''
                )}
                onClick={() => handleAnimationEnd(button.id === 'left')}
              >
                {button.icon}
              </button>
            ))}
          </div>
        )}
      </div>
      {tabs[activeIndex].content && tabs[activeIndex].content}
    </div>
  )
}

export default ScrollTabs
