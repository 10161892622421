import React from 'react'
import { Typography } from '@/ds/atoms/Typography'
import { Button, ButtonVariant } from '@/ds/atoms/Button'
import Image from 'next/image'
import { getStrapiMedia } from '@/utils/media'
import { StrapiImageComponent } from '@/types/strapiComponents'
import { VideoPlayer } from '@/ds/utils/VideoPlayer/VideoPlayer'

export type TestimonialCardProps = {
  quote: string
  author: string
  role: string
  logo?: StrapiImageComponent
  image?: StrapiImageComponent
  video?: StrapiImageComponent
  className?: string
  cta: {
    btnText: string
    target?: '_self' | '_blank' | '_parent' | '_top'
    link: string
    btnVariant: ButtonVariant
  }
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  quote,
  author,
  role,
  logo,
  image,
  video,
  className,
  cta,
}) => {
  return (
    <div
      className={`flex flex-col-reverse w-[303px] items-center  bg-gradient-blue-light md:flex-row md:w-screen : xl:w-[1100px] h-full ${className}`}
    >
      {/* Content Section */}
      <div className="flex-1 px-5 py-8 flex flex-col space-y-5 max-w-full h-[374px] justify-between md:max-w-[35vw] md:h-full">
        <div className="space-y-4 sm:space-y-8">
          {/* Quote */}
          <Typography
            variant="header4"
            text={quote}
            className="text-grays text-wrap break-words"
            as="p"
          />

          {/* Author Info */}
          <div className="space-y-1">
            <Typography
              variant="body16"
              text={author}
              className="text-grays text-wrap break-words"
            />
            <Typography
              variant="body14"
              text={role}
              className="text-grays-light text-wrap break-words"
            />
          </div>
        </div>
        <div className="space-y-6">
          {/* Ratio Badge */}
          {logo && (
            <div className="relative w-[120px] h-[60px]">
              <Image
                src={getStrapiMedia(logo?.url)}
                alt={logo?.alternativeText ?? 'logo'}
                layout="fill"
                objectFit="contain"
                priority
                loading="eager"
              />
            </div>
          )}
          {/* Read More Button */}
          {cta && (
            <Button
              variant={cta?.btnVariant || 'onlyText'}
              color="primary"
              label={cta?.btnText}
              withIcon
              as="a"
              href={cta?.link}
              target={cta?.target || '_blank'}
            />
          )}
        </div>
      </div>

      {/* Media Section */}

      {video ? (
        <VideoPlayer
          className="w-full h-[228px] sm:h-fit sm:max-w-md xl:max-w-full xl:h-[448px] xl:w-[582px]"
          src={getStrapiMedia(video?.url)}
          options={{
            ratio: '1.33:1',
          }}
        />
      ) : (
        image && (
          <div className="relative w-full h-[228px] sm:h-fit sm:max-w-md sm:aspect-[13/10] xl:max-w-full xl:h-[448px] xl:w-[582px]">
            <Image
              src={getStrapiMedia(image?.url)}
              alt={image?.alternativeText ?? 'image'}
              className="object-cover"
              fill
              priority
              loading="eager"
            />
          </div>
        )
      )}
    </div>
  )
}

export default TestimonialCard
