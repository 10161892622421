'use client'
import { Typography } from '@/ds/atoms/Typography'
import ThankBox from '../ThankBox/ThankBox'
import DemoForm from '../DemoForm/DemoForm'
import React, { useState } from 'react'
import { cn } from '@/ds/utils/cn'
import { StrapiLinkComponent } from '@/types/strapiComponents'

type NativeFormPropsType = {
  formId: string
  buttonId: string
  formTitle: string
  formDescription?: string
  submitButtonText: string
  wrapperClassName?: string
  demoFormTncText?: string
  tncLink?: StrapiLinkComponent
}

function NativeForm({
  formId,
  buttonId,
  formTitle,
  formDescription,
  submitButtonText,
  wrapperClassName,
  demoFormTncText,
  tncLink,
}: NativeFormPropsType) {
  const [showThanksBox, setShowThanksBox] = useState<boolean>(false)

  return (
    <div
      className={cn(
        'p-0 md:p-5 primary-box-shadow rounded-[8px]',
        wrapperClassName
      )}
    >
      <div>
        {showThanksBox ? (
          <ThankBox />
        ) : (
          <>
            <Typography
              className="text-grays"
              variant="header3"
              text={formTitle}
            />
            {formDescription && (
              <Typography
                className="text-grays-lighter"
                variant="body16"
                text={formDescription}
              />
            )}
            <div className="hubspot_ar-wrap w-full bg-white mt-8">
              <DemoForm
                setShowThanksBox={setShowThanksBox}
                formId={formId}
                buttonId={buttonId}
                submitButtonText={submitButtonText}
                demoFormTncText={demoFormTncText}
                tncLink={tncLink}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default NativeForm
