'use client'
import Pagination from '@/components/Pagination/Pagination'
import { Button } from '@/ds/atoms/Button'
import Container from '@/ds/atoms/Container/Container'
import { Typography } from '@/ds/atoms/Typography'
import { cn } from '@/ds/utils/cn'
import { IContextData } from '@/types/page'
import { getStrapiMedia } from '@/utils/media'
import { useLocale } from 'next-intl'
import { ReactNode, useMemo, useState } from 'react'
import Image from 'next/image'

export type SuccessStoriesPropsType = {
  title: string
  filterBySizeTitle: string
  filterByIndustryTitle: string
  noTestimonialsFoundTitle: string
  noTestimonialsFoundSubtitle: string
  previousButtonLabel: string
  nextButtonLabel: string
  contextData: IContextData
  className?: string
}

const SuccessStories = ({
  title,
  filterBySizeTitle,
  filterByIndustryTitle,
  noTestimonialsFoundTitle,
  noTestimonialsFoundSubtitle,
  previousButtonLabel,
  nextButtonLabel,
  contextData,
  className,
}: SuccessStoriesPropsType): ReactNode => {
  const locale = useLocale()
  const { successStoriesData: testimonials } = contextData
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedIndustries, setSelectedIndustries] = useState([])
  const [selectedSizes, setSelectedSizes] = useState([])
  const itemsPerPage = 9

  // Available filter options
  const industries = useMemo(() => {
    const uniqueIndustries = testimonials.reduce((acc, testimonial) => {
      if (!acc.includes(testimonial.industry)) {
        acc.push(testimonial.industry)
      }
      return acc
    }, [] as string[])
    return uniqueIndustries
  }, [testimonials])

  const companySizes = useMemo(() => {
    const uniqueCompanySizes = testimonials.reduce((acc, testimonial) => {
      if (!acc.includes(testimonial.companySize)) {
        acc.push(testimonial.companySize)
      }
      return acc
    }, [] as string[])
    return uniqueCompanySizes
  }, [testimonials])

  // Filter handling
  const toggleIndustryFilter = industry => {
    setSelectedIndustries(prev =>
      prev.includes(industry)
        ? prev.filter(i => i !== industry)
        : [...prev, industry]
    )
    setCurrentPage(1)
  }

  const toggleSizeFilter = size => {
    setSelectedSizes(prev =>
      prev.includes(size) ? prev.filter(s => s !== size) : [...prev, size]
    )
    setCurrentPage(1)
  }

  // Filter testimonials
  const filteredTestimonials = useMemo(() => {
    return testimonials.filter(testimonial => {
      const industryMatch =
        selectedIndustries.length === 0 ||
        selectedIndustries.includes(testimonial.industry)
      const sizeMatch =
        selectedSizes.length === 0 ||
        selectedSizes.includes(testimonial.companySize)
      return industryMatch && sizeMatch
    })
  }, [testimonials, selectedIndustries, selectedSizes])

  // Pagination
  const totalPages = Math.ceil(filteredTestimonials.length / itemsPerPage)
  const paginatedTestimonials = filteredTestimonials.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const handlePageChange = page => {
    setCurrentPage(page)
    // Optionally scroll to top of grid
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className={cn('bg-success-story-section', className)}>
      <Container>
        {/* Header */}
        <div className="mb-8 lg:mb-12">
          <Typography
            variant="header2"
            className="text-primary-darkest"
            text={title}
          />
        </div>

        {/* Filters */}
        <div className="flex flex-col lg:flex-row mb-8 lg:mb-12 gap-6 lg:gap-16">
          <div className="w-full lg:w-1/2">
            <Typography
              text={filterByIndustryTitle}
              variant="body20"
              className="text-grays mb-2"
            />
            <div className="flex flex-wrap gap-3">
              {industries.map(industry => (
                <Button
                  as="button"
                  key={industry}
                  variant={
                    selectedIndustries.includes(industry)
                      ? 'filled'
                      : 'outlined'
                  }
                  onClick={() => toggleIndustryFilter(industry)}
                  className={cn(
                    'rounded-lg px-4 py-1 border-grays-lightest text-grays-lighter',
                    {
                      'text-white': selectedIndustries.includes(industry),
                    }
                  )}
                  label={
                    locale === 'en'
                      ? industry.split('-')?.at(0)
                      : industry.split('-')?.at(1)
                  }
                />
              ))}
            </div>
          </div>

          <div className="w-full lg:w-1/2">
            <Typography
              text={filterBySizeTitle}
              variant="body20"
              className="text-grays mb-2"
            />
            <div className="flex flex-wrap gap-3">
              {companySizes.map(size => (
                <Button
                  as="button"
                  key={size}
                  variant={selectedSizes.includes(size) ? 'filled' : 'outlined'}
                  onClick={() => toggleSizeFilter(size)}
                  className={cn(
                    'rounded-lg px-4 py-1 border-grays-lightest text-grays-lighter',
                    {
                      'text-white': selectedSizes.includes(size),
                    }
                  )}
                  label={size.split('-').slice(1).join('-')}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {paginatedTestimonials.map(testimonial => (
            <div
              role="presentation"
              key={testimonial.id}
              onClick={(e: any) => {
                if (!testimonial?.slug || e.target.tagName === 'A') return
                window.open(
                  `success-stories/${testimonial?.slug}`,
                  testimonial?.linkTarget
                )
              }}
              className={cn(
                'bg-white relative overflow-hidden mx-auto rounded-[8px] group min-h-[446px]',
                {
                  'cursor-pointer': testimonial?.slug,
                }
              )}
            >
              <div className="relative min-w-full rounded-t-[8px] h-[300px] overflow-hidden">
                <Image
                  src={getStrapiMedia(testimonial.image?.url)}
                  alt={testimonial.image?.alternativeText}
                  className="object-cover"
                  fill
                  priority
                  loading="eager"
                />
              </div>
              <p className="bg-primary-light select-none text-white rounded-lg px-3 py-1 absolute top-4 right-4">
                {locale === 'en'
                  ? testimonial.industry.split('-')?.at(0)
                  : testimonial.industry.split('-')?.at(1)}
              </p>
              <div
                className={cn(
                  'p-4 relative duration-200 ease-in max-h-[180px] z-10 bg-white',
                  {
                    'group-hover:-mt-[32px]': testimonial?.slug,
                  }
                )}
              >
                <div className="flex flex-col gap-3">
                  <div className="relative w-[111px] h-[48px] mb-1">
                    <Image
                      src={getStrapiMedia(testimonial.companyLogo?.url)}
                      alt={testimonial.companyLogo?.alternativeText}
                      className="object-contain"
                      fill
                      priority
                      loading="eager"
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Typography
                      variant="body20"
                      className="font-bold text-grays"
                      text={testimonial.name}
                    />
                    <Typography
                      variant="body16"
                      className="text-grays-lighter"
                      text={testimonial.content}
                    />
                  </div>
                  {testimonial?.slug && (
                    <Button
                      as="a"
                      href={`success-stories/${testimonial?.slug}`}
                      target={testimonial?.linkTarget}
                      label={testimonial?.linkText}
                      variant="onlyText"
                      className="justify-start hidden group-hover:flex"
                      withIcon
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            previousButtonLabel={previousButtonLabel}
            nextButtonLabel={nextButtonLabel}
          />
        )}

        {totalPages === 0 && (
          <div className="flex flex-col items-center justify-center gap-4 mt-6 my-6">
            <Typography
              variant="header3"
              text={noTestimonialsFoundTitle}
              className="text-primary-darkest"
            />
            <Typography
              variant="body16"
              text={noTestimonialsFoundSubtitle}
              className="text-grays-lighter"
            />
          </div>
        )}
      </Container>
    </div>
  )
}

export default SuccessStories
