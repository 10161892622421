'use client'
import Pagination from '@/components/Pagination/Pagination'
import { Button } from '@/ds/atoms/Button'
import Container from '@/ds/atoms/Container/Container'
import { Typography } from '@/ds/atoms/Typography'
import { cn } from '@/ds/utils/cn'
import { IContextData } from '@/types/page'
import { getStrapiMedia } from '@/utils/media'
import { useLocale } from 'next-intl'
import { ReactNode, useMemo, useState } from 'react'
import Image from 'next/image'

export type ValuedCustomersPropsType = {
  title: string
  filterBySizeTitle: string
  filterByIndustryTitle: string
  noTestimonialsFoundTitle: string
  noTestimonialsFoundSubtitle: string
  previousButtonLabel: string
  nextButtonLabel: string
  contextData: IContextData
  className?: string
}

const ValuedCustomers = ({
  title,
  filterBySizeTitle,
  filterByIndustryTitle,
  noTestimonialsFoundTitle,
  noTestimonialsFoundSubtitle,
  previousButtonLabel,
  nextButtonLabel,
  contextData,
  className,
}: ValuedCustomersPropsType): ReactNode => {
  const locale = useLocale()
  const { valuedCustomersData: testimonials } = contextData
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedIndustries, setSelectedIndustries] = useState([])
  const [selectedSizes, setSelectedSizes] = useState([])
  const itemsPerPage = 12

  // Available filter options
  const industries = useMemo(() => {
    const uniqueIndustries = testimonials.reduce((acc, testimonial) => {
      if (!acc.includes(testimonial.industry)) {
        acc.push(testimonial.industry)
      }
      return acc
    }, [] as string[])
    return uniqueIndustries
  }, [testimonials])

  const companySizes = useMemo(() => {
    const uniqueCompanySizes = testimonials.reduce((acc, testimonial) => {
      if (!acc.includes(testimonial.companySize)) {
        acc.push(testimonial.companySize)
      }
      return acc
    }, [] as string[])
    return uniqueCompanySizes
  }, [testimonials])

  // Filter handling
  const toggleIndustryFilter = industry => {
    setSelectedIndustries(prev =>
      prev.includes(industry)
        ? prev.filter(i => i !== industry)
        : [...prev, industry]
    )
    setCurrentPage(1)
  }

  const toggleSizeFilter = size => {
    setSelectedSizes(prev =>
      prev.includes(size) ? prev.filter(s => s !== size) : [...prev, size]
    )
    setCurrentPage(1)
  }

  // Filter testimonials
  const filteredTestimonials = useMemo(() => {
    return testimonials.filter(testimonial => {
      const industryMatch =
        selectedIndustries.length === 0 ||
        selectedIndustries.includes(testimonial.industry)
      const sizeMatch =
        selectedSizes.length === 0 ||
        selectedSizes.includes(testimonial.companySize)
      return industryMatch && sizeMatch
    })
  }, [testimonials, selectedIndustries, selectedSizes])

  // Pagination
  const totalPages = Math.ceil(filteredTestimonials.length / itemsPerPage)
  const paginatedTestimonials = filteredTestimonials.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const handlePageChange = page => {
    setCurrentPage(page)
    // Optionally scroll to top of grid
    window?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className={cn(className)}>
      <Container>
        {/* Header */}
        <div className="mb-8 lg:mb-12">
          <Typography
            variant="header2"
            className="text-primary-darkest"
            text={title}
          />
        </div>

        {/* Filters */}
        <div className="flex flex-col lg:flex-row mb-8 lg:mb-12 gap-6 lg:gap-16">
          <div className="w-full lg:w-1/2">
            <Typography
              text={filterByIndustryTitle}
              variant="body20"
              className="text-grays mb-2"
            />
            <div className="flex flex-wrap gap-3">
              {industries.map(industry => (
                <Button
                  as="button"
                  key={industry}
                  variant={
                    selectedIndustries.includes(industry)
                      ? 'filled'
                      : 'outlined'
                  }
                  onClick={() => toggleIndustryFilter(industry)}
                  className={cn(
                    'rounded-lg px-4 py-1 border-grays-lightest text-grays-lighter',
                    {
                      'text-white': selectedIndustries.includes(industry),
                    }
                  )}
                  label={
                    locale === 'en'
                      ? industry.split('-')?.at(0)
                      : industry.split('-')?.at(1)
                  }
                />
              ))}
            </div>
          </div>

          <div className="w-full lg:w-1/2">
            <Typography
              text={filterBySizeTitle}
              variant="body20"
              className="text-grays mb-2"
            />
            <div className="flex flex-wrap gap-3">
              {companySizes.map(size => (
                <Button
                  as="button"
                  key={size}
                  variant={selectedSizes.includes(size) ? 'filled' : 'outlined'}
                  onClick={() => toggleSizeFilter(size)}
                  className={cn(
                    'rounded-lg px-4 py-1 border-grays-lightest text-grays-lighter',
                    {
                      'text-white': selectedSizes.includes(size),
                    }
                  )}
                  label={size.split('-').slice(1).join('-')}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mb-8">
          {paginatedTestimonials.map(testimonial => (
            <div
              key={testimonial.id}
              className="bg-valued-customers-card relative overflow-hidden w-full mx-auto px-5 py-4 rounded-lg"
            >
              <div className="relative w-[200px] h-[100px] mx-auto mb-4">
                <Image
                  src={getStrapiMedia(testimonial.companyLogo?.url)}
                  alt={testimonial.companyLogo?.alternativeText}
                  className="object-contain rounded-t-[8px]"
                  fill
                  priority
                  loading="eager"
                />
              </div>
              <div className="flex justify-center">
                <div className="bg-industry-badge py-1 px-3 inline-block rounded-lg">
                  {testimonial.industry}
                </div>
              </div>
              <Typography
                variant="header4"
                text={testimonial.name}
                className="text-grays text-center mt-4"
              />
            </div>
          ))}
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            previousButtonLabel={previousButtonLabel}
            nextButtonLabel={nextButtonLabel}
          />
        )}

        {totalPages === 0 && (
          <div className="flex flex-col items-center justify-center gap-4 mt-6 my-6">
            <Typography
              variant="header3"
              text={noTestimonialsFoundTitle}
              className="text-primary-darkest"
            />
            <Typography
              variant="body16"
              text={noTestimonialsFoundSubtitle}
              className="text-grays-lighter"
            />
          </div>
        )}
      </Container>
    </div>
  )
}

export default ValuedCustomers
