'use client'
import React, { useRef, useState, useEffect } from 'react'
import { ScrollTabs } from '@/ds/atoms/ScrollTabs'
import { cn } from '@/ds/utils/cn'
import styles from './FeaturesBenefitsSection.module.css'
import PrecisionCard, { PrecisionCardPropsType } from './PrecisionCard'
import { Container } from '@/ds/atoms/Container'

export type FeaturesBenefitsSectionPropsType = {
  cards: PrecisionCardPropsType[]
  className?: string
}

const FeaturesBenefitsSection: React.FC<FeaturesBenefitsSectionPropsType> = ({
  cards,
  className,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const cardRefs = useRef<(HTMLDivElement | null)[]>([])

  // Convert testimonials to tab format
  const tabs = cards?.map((_, index) => ({
    id: index.toString(),
  }))

  // Handle scroll synchronization
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    const handleScroll = () => {
      const containerRect = scrollContainer.getBoundingClientRect()
      let newActiveIndex = 0
      let minDistance = Infinity

      cardRefs.current?.forEach((cardRef, index) => {
        if (cardRef) {
          const cardRect = cardRef.getBoundingClientRect()
          const distance = Math.abs(cardRect.left - containerRect.left)
          if (distance < minDistance) {
            minDistance = distance
            newActiveIndex = index
          }
        }
      })

      if (newActiveIndex !== activeIndex) {
        setActiveIndex(newActiveIndex)
      }
    }

    const debouncedHandleScroll = debounce(handleScroll, 100)
    scrollContainer.addEventListener('scroll', debouncedHandleScroll)
    return () =>
      scrollContainer.removeEventListener('scroll', debouncedHandleScroll)
  }, [activeIndex])

  // Debounce function to improve scroll performance
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  function debounce(func: Function, wait: number) {
    let timeout: ReturnType<typeof setTimeout>
    return function executedFunction(...args: any[]) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  // Scroll to card when tab is clicked
  const handleTabChange = (index: number) => {
    const card = cardRefs.current[index]
    if (card && scrollContainerRef.current) {
      const cardRect = card.getBoundingClientRect()
      const containerRect = scrollContainerRef.current.getBoundingClientRect()
      const scrollLeft =
        cardRect.left -
        containerRect.left +
        scrollContainerRef.current.scrollLeft
      scrollContainerRef.current.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className={className}>
      <Container>
        {/* Precision Scroll Container */}
        <div
          ref={scrollContainerRef}
          className={cn('w-full overflow-x-auto mb-8', styles.scrollbarHide)}
          onWheel={e => e.preventDefault()}
          onKeyDown={e => e.preventDefault()}
        >
          <div className="flex gap-5 sm:gap-8">
            {cards?.map((testimonial, index) => (
              <div
                key={index}
                ref={el => {
                  cardRefs.current[index] = el
                }}
              >
                <PrecisionCard {...testimonial} />
              </div>
            ))}
          </div>
        </div>

        {/* Scroll Tabs */}
        <div className="w-full flex items-start">
          <ScrollTabs
            tabs={tabs}
            activeIndex={activeIndex}
            setActiveIndex={index => {
              setActiveIndex(typeof index === 'number' ? index : 0)
              handleTabChange(typeof index === 'number' ? index : 0)
            }}
            mode="manual"
            tabProps={{
              showLoader: false,
              visualType: 'slider',
              showNavButtons: false,
              alignment: 'horizontal',
              containerClassname: 'gap-2',
              color: 'primary',
            }}
          />
        </div>
      </Container>
    </div>
  )
}

export default FeaturesBenefitsSection
