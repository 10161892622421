'use client'

import { cn } from '@/ds/utils/cn'
import React from 'react'

export type AccordionProps = {
  open?: boolean
  title: React.ReactNode
  content: React.ReactNode
  titleClassName?: string
  contentClassName?: string
  variant?: 'plus' | 'arrow'
  containerClassName?: string
  setCurrentStep?: () => void
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  content,
  titleClassName,
  contentClassName,
  open,
  containerClassName,
  variant = 'arrow',
  setCurrentStep,
}) => {
  return (
    <details
      className={cn('w-full p-4 group/acc', containerClassName)}
      open={open}
      {...(setCurrentStep
        ? {
            onClick: e => {
              e.preventDefault()
              setCurrentStep?.()
            },
          }
        : {})}
    >
      <summary
        className={cn(
          'flex justify-between items-center cursor-pointer list-none font-medium',
          titleClassName
        )}
      >
        {title}
        {variant === 'plus' ? (
          <span className="relative w-5 h-5 scale-50">
            <span className="absolute top-1/2 left-0 w-5 h-0.5 -translate-y-1/2 bg-current group-open/acc:rotate-180 transition-transform" />
            <span className="absolute top-0 left-1/2 h-5 w-0.5 -translate-x-1/2 bg-current group-open/acc:rotate-90 transition-transform" />
          </span>
        ) : (
          <svg
            className="w-4 h-4 transition-transform group-open/acc:rotate-180"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        )}
      </summary>
      <div
        className={cn(
          'pt-4 mt-4 border-t border-grays-bg bg-white',
          contentClassName
        )}
      >
        {content}
      </div>
    </details>
  )
}

export default Accordion
