import { FC } from 'react'
import Typography, { TypographyProps } from '../Typography/Typography'
import { cn } from '@/ds/utils/cn'
import { useLocale } from 'next-intl'
import Button from '../Button/Button'
import { StrapiLinkComponent } from '@/types/strapiComponents'

interface CardProps {
  children: React.ReactNode
  title: string
  description: string
  titleProps?: TypographyProps
  descriptionProps?: TypographyProps
  className?: string
  color?: string[]
  variant?: 'solution' | 'department' | 'feature'
  linkClassName?: string
  link?: StrapiLinkComponent
  onClick?: () => void
}

const Card: FC<CardProps> = ({
  children,
  title,
  description,
  link,
  titleProps,
  descriptionProps,
  className,
  linkClassName,
  variant = 'solution',
}) => {
  const locale = useLocale()
  const containerCardStyles = {
    solution: 'w-[606px] max-w-full p-2 pb-6 rounded',
    department: 'w-[293px] p-0 pb-6',
    feature: 'w-[398px] p-5 pb-5 rounded-2xl',
  }
  const contentCardStyles = {
    solution: 'flex flex-col gap-3 px-5',
    department: 'flex flex-col gap-4 px-4 text-[#eee]',
    feature: 'flex flex-col gap-2',
  }

  return (
    // need to add card variants
    <div className={cn(containerCardStyles[variant], className)}>
      {children}

      {/*  need to add card variants */}
      <div className={contentCardStyles[variant]}>
        {/* title */}
        <Typography
          text={title}
          variant="header3"
          {...titleProps}
        />
        {/* description */}
        {description && (
          <Typography
            text={description}
            variant="body16"
            {...descriptionProps}
          />
        )}
        {/* link */}
        {link && (
          <Button
            as="a"
            href={link?.link}
            target={link?.target}
            label={link?.text}
            {...(link?.linkId && { id: link?.linkId })}
            hrefLang={locale}
            variant="onlyText"
            withIcon
            className={cn('justify-start', linkClassName)}
          />
        )}
      </div>
    </div>
  )
}

export default Card
