'use client'
import { Button } from '@/ds/atoms/Button'
import Card from '@/ds/atoms/Card'
import { Container } from '@/ds/atoms/Container'
import { Typography } from '@/ds/atoms/Typography'
import { cn } from '@/ds/utils/cn'
import {
  StrapiImageCardComponent,
  StrapiLinkComponent,
} from '@/types/strapiComponents'
import { getStrapiMedia } from '@/utils/media'
import React from 'react'
import Image from 'next/image'

export type WhoWeServePropsType = {
  cards: StrapiImageCardComponent[]
  cta?: StrapiLinkComponent
  title: string
  description: string
  className?: string
}

function WhoWeServe({
  cards,
  title,
  description,
  cta,
  className,
}: WhoWeServePropsType) {
  return (
    <div
      className={cn(
        'bg-primary rounded-tl-[64px] lg:rounded-tl-[160px]',
        className
      )}
    >
      <Container>
        <div className="mb-16 text-center">
          <Typography
            className="text-white mb-2"
            variant="header2"
            text={title}
          />
          <Typography
            className="text-white max-w-4xl mx-auto text-center"
            variant="body20"
            text={description}
          />
        </div>
        <div className="mb-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 flex-wrap justify-center ">
          {cards?.map(card => {
            return (
              <div
                role="presentation"
                key={card.id}
                onClick={() => {
                  window.open(card?.link?.link, card?.link?.target || '_blank')
                }}
              >
                <Card
                  className="w-full group hover:cursor-pointer"
                  variant="department"
                  title={card.title}
                  description={card.description}
                  link={card?.link}
                  linkClassName="text-white transition-opacity duration-500 ease-in-out opacity-0 group-hover:opacity-100"
                >
                  <div className="relative group overflow-hidden w-auto h-[293px] rounded-tl-[32px] mb-4 mx-auto">
                    <Image
                      className="object-contain rounded-tl-[32px] md:ml-0 transition-transform duration-1000 ease-[cubic-bezier(0.4, 0, 0.2, 1)] transform scale-100 group-hover:scale-105 opacity-100 group-hover:opacity-0"
                      src={getStrapiMedia(card.image.url)}
                      alt={card.image?.alternativeText}
                      fill
                      priority
                      loading="eager"
                    />
                    <Image
                      className="object-contain absolute top-0 left-0 w-full h-full rounded-tl-[32px] transition-transform duration-1000 ease-[cubic-bezier(0.4, 0, 0.2, 1)] transform scale-95 opacity-0 group-hover:opacity-100 group-hover:scale-100"
                      src={getStrapiMedia(card.hoverImage?.url)}
                      alt={`${card.hoverImage?.alternativeText}`}
                      fill
                      priority
                      loading="eager"
                    />
                  </div>
                </Card>
              </div>
            )
          })}
        </div>
        <div className="text-center">
          {cta ? (
            <Button
              id={cta?.linkId}
              className="min-w-[160px] justify-between"
              as="a"
              color="white"
              withIcon
              target={cta?.target}
              href={cta?.link}
              label={cta?.text}
            />
          ) : null}
        </div>
      </Container>
    </div>
  )
}

export default WhoWeServe
