import { Button, ButtonVariant } from '@/ds/atoms/Button'
import { Typography } from '@/ds/atoms/Typography'
import Image from 'next/image'
import React from 'react'
import { getStrapiMedia } from '@/utils/media'
import { StrapiImageComponent } from '@/types/strapiComponents'

export type PrecisionCardPropsType = {
  data: {
    title: string
    description: string
    cta: {
      btnText: string
      target: string
      link: string
      btnVariant: ButtonVariant
    }
  }
  heroImage: StrapiImageComponent
}

const PrecisionCard = ({
  data: { title, description, cta },
  heroImage,
}: PrecisionCardPropsType) => {
  return (
    <div className="testimonial-card flex flex-col-reverse h-full lg:flex-row lg: justify-between items-center lg:w-[calc(100vw-220px)] lg:max-w-[1194px] bg-white p-4 sm:p-5 gap-5 rounded-lg">
      {/* Left content section */}
      <div className="flex-1 flex-col max-w-[271px] lg:max-w-[50vh]">
        <Typography
          text={title}
          className="text-grays-bold"
          variant="header3"
          as="h2"
        />
        <Typography
          text={description}
          className="text-grays-lighter mt-2"
          variant="body16"
        />
        {!!cta && (
          <Button
            as="a"
            href={cta?.link}
            label={cta?.btnText}
            target={cta?.target}
            color="primary"
            withIcon
            className="w-fit mt-5"
            variant={cta?.btnVariant || 'filled'}
          />
        )}
      </div>

      {/* Right ratio card section */}
      <div className="p-5 flex items-center justify-center bg-gradient-blue-lighter rounded-lg">
        <div className="relative w-[239px] h-[191px] md:h-[356px] md:w-[446px] lg:w-[50vh] p-5 pb-0">
          <Image
            src={getStrapiMedia(heroImage?.url)}
            alt={heroImage?.alternativeText}
            fill
            className="object-cover"
            priority
            loading="eager"
          />
        </div>
      </div>
    </div>
  )
}

export default PrecisionCard
