'use client'
import { Typography } from '@/ds/atoms/Typography'
import { cn } from '@/ds/utils/cn'
import { Link } from '@/i18n/routing'
import { getStrapiMedia } from '@/utils/media'
import { useLocale } from 'next-intl'
import React from 'react'
import { ContentChild, ContentNode, TextNode } from './types'
import RenderIframeContent from '../RenderCodeContent/RenderCodeContent'
export interface ContentRendererProps {
  content: ContentNode[]
}

const RenderTextWithFormatting = ({ node }: { node: TextNode }) => {
  return (
    <span
      className={cn({
        'font-bold text-grays': node.bold,
        italic: node.italic,
        underline: node.underline,
        'line-through': node.strikethrough,
      })}
    >
      {node.text}
    </span>
  )
}

const RenderContentChild = ({
  child,
  index,
}: {
  child: ContentChild
  index: number
}) => {
  const locale = useLocale()

  switch (child.type) {
    case 'text':
      return <RenderTextWithFormatting node={child} />
    case 'link':
      return (
        <Link
          key={index}
          href={child.url as any}
          className="text-blue-600 hover:text-blue-800 underline"
          target="_blank"
          rel="noopener noreferrer"
          hrefLang={locale}
        >
          {child.children.map((textNode, index) => (
            <RenderTextWithFormatting
              key={index}
              node={textNode}
            />
          ))}
        </Link>
      )
    default:
      return null
  }
}

const RenderNode = ({ node }: { node: ContentNode }) => {
  switch (node.type) {
    case 'heading': {
      const headeringTag = node?.level < 3 ? `header3` : `header${node?.level}`
      return (
        <Typography
          variant={headeringTag as any}
          className="mb-4"
          as={`h${node?.level}`}
          text={
            <>
              {node.children.map((child, index) => (
                <RenderTextWithFormatting
                  key={index}
                  node={child}
                />
              ))}
            </>
          }
        />
      )
    }
    case 'paragraph':
      return (
        <Typography
          variant="body16"
          className="mb-4 text-grays-lighter"
          text={node.children.map((child, index) => (
            <RenderContentChild
              key={index}
              child={child}
              index={index}
            />
          ))}
        />
      )

    case 'list': {
      const ListTag = node?.format === 'ordered' ? 'ol' : 'ul'
      const listClassName =
        node?.format === 'ordered' ? 'list-decimal' : 'list-disc'
      return (
        <ListTag className={cn('mb-4 ms-6 text-grays-lighter', listClassName)}>
          {node.children.map((child, index) => {
            return (
              <RenderNode
                key={index}
                node={child}
              />
            )
          })}
        </ListTag>
      )
    }
    case 'list-item':
      return (
        <li className="mb-1">
          {node.children.map((child, index) => (
            <RenderContentChild
              key={index}
              child={child}
              index={index}
            />
          ))}
        </li>
      )
    case 'quote':
      return (
        <div className="px-4 py-4 border-s-4 border-slate-400  text-grays-lighter">
          {node.children.map((child, index) => (
            <RenderContentChild
              key={index}
              child={child}
              index={index}
            />
          ))}
        </div>
      )

    case 'image':
      return (
        <img
          src={getStrapiMedia(node.image.url)}
          alt={node.image.alternativeText}
          className="w-full h-full"
        />
      )

    case 'code':
      return node.children.map((child, index) => {
        return (
          <RenderIframeContent
            text={child.text}
            type={child.type}
            key={index}
          />
        )
      })

    default:
      return null
  }
}

const ContentRenderer = ({ content }: ContentRendererProps) => {
  return (
    <>
      {content.map((node, index) => (
        <div key={index}>
          <RenderNode node={node} />
        </div>
      ))}
    </>
  )
}

export default ContentRenderer
